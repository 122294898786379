export default function(instance) {
	return {
		// ? VOTE (ГОЛОСОВАНИЯ)
		// * Получение всех голосований
		load_votes(params) {
			return instance({
				url: `/v2/ksk/votes`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Просмотр конкретного голосования
		load_vote_info(id) {
			return instance({
				url: `/v2/ksk/votes/${id}`,
				method: "get",
			})
				.then(res => res.data.vote)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление нового голосования
		add_vote(data) {
			return instance({
				url: `/v2/ksk/votes`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление вопроса
		add_vote_question(vote_id, data) {
			return instance({
				url: `/v2/ksk/votes/questions/${vote_id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Редактирование вопроса
		edit_vote_question(id, data) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/update`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление вопроса
		delete_vote_question(id) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление файла в вопросе голосования
		delete_file_question(id, fileId) {
			return instance({
				url: `/v2/ksk/votes/questions/${id}/delete/file/${fileId}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение квартир, участвующих в голосвовании
		load_vote_apartments(vote_id, params) {
			return instance({
				url: `/v2/ksk/votes/apartments/${vote_id}`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		//  * Добавление квартиры
		vote_add_single_apartment(data) {
			return instance({
				url: `/v2/ksk/votes/apartments`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		//  * Добавление квартиры
		vote_store_apartment(data) {
			return instance({
				url: `/v2/ksk/votes/apartments/store/apartment`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Добавление множества квартир
		vote_add_multiple_apartments(data) {
			return instance({
				url: `/v2/ksk/votes/apartments/many/store`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление квартиры
		delete_apartment(id) {
			return instance({
				url: `/v2/ksk/votes/apartments/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Изменения ИИН квартиры
		set_apartment_iin(apartment_id, data) {
			return instance({
				url: `/v2/ksk/votes/apartments/${apartment_id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Публикация голосования
		publish_vote(id, data) {
			return instance({
				url: `/v2/ksk/votes/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Удаление голосования
		delete_vote(id) {
			return instance({
				url: `/v2/ksk/votes/destroy/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		// * Получение протокола
		get_protocol(id) {
			return instance({
				url: `/v2/ksk/votes/publish/report/${id}`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
		// * Получение архива
		get_zip(id, params) {
			return instance({
				url: `/v2/ksk/votes/zip/${id}`,
				method: "post",
				responseType: 'blob',
				data: params
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
