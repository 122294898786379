export default function(instance) {
	return {
		login(data) {
			return instance({
				url: "/authorization",
				method: "post",
				data
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		logout(data) {
			return instance({
				url: "/logout",
				method: "post",
				data
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		load_logs(params) {
			return instance({
				url: "/logs",
				method: "get",
				params
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		update_access() {
			return instance({
				url: "/access",
				method: "post",
				data: {
					application: "company"
				}
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},
		update_refresh() {
			return instance({
				url: "/refresh",
				method: "post",
				data: {
					application: "company"
				}
			})
				.then(res => res)
				.catch(e => {
					throw e;
				});
		},

		load_data(params, id) {
			return instance({
				url: `/users/${id}`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(e => e);
		},

		load_user_info(id) {
			return instance({
				url: `/v2/user/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(e => e);
		},

		update_user_info(data, id) {
			return instance({
				url: `/users/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => e);
		},

		get_data_by_phone({ phone, params }) {
			return instance({
				url: params.statisticUrl
					? params.statisticUrl
					: "/v2/administrator/ksk/users",
				method: "get",
				params: { phone: phone },
			})
				.then(res => res.data.records[0])
				.catch(e => e);
		},

		confirm_address({ owner_id, params }) {
			return instance({
				url: params.statisticUrl
					? `${params.statisticUrl}/owner/${owner_id}/confirm`
					: `/v2/administrator/ksk/users/owner/${owner_id}/confirm`,
				method: "post",
			})
				.then(res => res)
				.catch(e => e);
		},

		change_password(data, id) {
			return instance({
				url: `/users/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},

		generate_password(data) {
			return instance({
				url: "/users/generate-sms-code",
				method: "POST",
				data,
			})
				.then(res => res.data)
				.catch(e => {
					throw e;
				});
		},
	};
}
