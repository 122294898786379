export default function(instance) {
	return {
		add_question(data) {
			return instance({
				url: "/v2/ksk/questions",
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		load_questions(params) {
			return instance({
				url: "/v2/ksk/questions",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		delete_question(id) {
			return instance({
				url: `/v2/administrator/questions/${id}/destroy`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		edit_question({ id, data }) {
			return instance({
				url: `/v2/administrator/questions/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		load_answer(params) {
			return instance({
				url: `/v2/ksk/answer`,
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => err);
		},

		add_answer(data) {
			return instance({
				url: `/v2/ksk/answer`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		edit_answer({ id, data }) {
			return instance({
				url: `/v2/ksk/answer/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(err => err);
		},

		delete_answer(id) {
			return instance({
				url: `/v2/ksk/answer/${id}/delete`,
				method: "post",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
