import $api from "@/api/index";

const state = {
	qrExists: false,
	analytic: null,
	statistic: null,
	analyticParams: null,
	analyticType: "request",
	isLoadingAnalytic: false,
	ksk_info: null,
	employee: [],
	houses: [],
	qrExistsHouses: [],
	tenants: [],
	organization_types: [
		{
				"_id": "5f4cda2f0796c90b114f553b",
				"id": 1,
				"name": "ИП"
		},
		{
				"_id": "5f4cda2f0796c90b114f553c",
				"id": 2,
				"name": "ТОО"
		},
		{
				"_id": "5f4cda2f0796c90b114f553d",
				"id": 3,
				"name": "ОО"
		},
		{
				"_id": "5f4cda2f0796c90b114f553e",
				"id": 4,
				"name": "КСПК"
		},
		{
				"_id": "5f4cda2f0796c90b114f553f",
				"id": 5,
				"name": "РГП"
		},
		{
				"_id": "5f4cda300796c90b114f5540",
				"id": 6,
				"name": "ПКСП"
		},
		{
				"_id": "5f4cda300796c90b114f5541",
				"id": 7,
				"name": "Учреждение"
		},
		{
				"_id": "5f4cda300796c90b114f5542",
				"id": 8,
				"name": "КСКП"
		},
		{
				"_id": "5f4cda300796c90b114f5543",
				"id": 9,
				"name": "КСК"
		},
		{
				"_id": "5f4cda300796c90b114f5544",
				"id": 10,
				"name": "КСКиНП"
		},
		{
				"_id": "5f4cda300796c90b114f5545",
				"id": 11,
				"name": "РГП"
		},
		{
				"_id": "5f4cda300796c90b114f5546",
				"id": 12,
				"name": "Старший по дому"
		},
		{
				"_id": "5f4cda300796c90b114f5547",
				"id": 13,
				"name": "ЖСК"
		},
		{
				"_id": "60dff40a4d6312062aa44cfc",
				"id": 14,
				"name": "ОСИ"
		},
		{
				"_id": "64cb6b7e86a3f3b59f8b1065",
				"id": 15,
				"name": "Сервисная компания"
		}
	],
};

const getters = {
	GET_ANALYTIC: state => state.analytic,
	IS_LOADING_ANALYTIC: state => state.isLoadingAnalytic,
	GET_KSK_INFO: state => state.ksk_info,
	GET_EMPLOYEE: state => state.employee,
	GET_EMPLOYEE_NAMES: state => {
		if(state.employee.length) {
			return state.employee.map(e => {
				return {
					id: e.id,
					fullname: (
						(e?.name || "") +
						" " +
						(e?.surname || "") +
						" " +
						(e?.patronymic || "")
					).trim(),
				};
			});
		} else {
			return []
		}
	},
	GET_HOUSES: state => state.houses,
	GET_NEWQR_EXIST: state => state.qrExistsHouses,
	GET_QR_EXISTS_HOUSES: state => state.qrExists,
	GET_HOUSES_ADDRESSES: state => state.houses.map(e => e.apartment_address),
	GET_TENANTS: state => state.tenants,
	GET_STATISTIC: state => state.statistic,
	GET_ORGANIZATION_TYPES: state => state.organization_types,
};

const mutations = {
	SET_ANALYTIC: (state, data) => (state.analytic = data),
	SET_ANALYTIC_LOADING: (state, data) => (state.isLoadingAnalytic = data),
	SET_ANALYTIC_PARAMS: (state, payload) => {
		if (payload === null) return (state.analyticParams = null);

		const data = { ...payload };

		for (const [key, value] of Object.entries(data)) {
			if (value === "") data[key] = null;
		}

		if (data.house_ids) data.house_ids = data.house_ids.map(e => e.id);

		if (!state.analyticParams) state.analyticParams = { ...data };
		else state.analyticParams = { ...state.analyticParams, ...data };
	},
	SET_ANALYTIC_TYPE: (state, data) => (state.analyticType = data),
	SET_KSK_INFO: (state, data) => (state.ksk_info = data),
	SET_EMPLOYEE: (state, data) => (state.employee = data),
	SET_HOUSES: (state, data) => {
    state.houses = data;
		console.log('data!', data)
    state.qrExists = data.some(house => house.hasOwnProperty('new_qr_exists') && house.new_qr_exists === true);
		state.qrExistsHouses = data.filter((house) => house.new_qr_exists === true);
		console.log('qrExistsHouses', state.qrExistsHouses)
  },
	SET_STATISTIC: (state, data) => (state.statistic = data),
	SET_TENANTS: (state, data) => (state.tenants = data),
	SET_ORGANIZATION_TYPES: (state, data) => (state.organization_types = data),
};

const actions = {
	async load_ksk_info({ rootGetters, commit }) {
		try {
			const ksk_id = rootGetters["auth/GET_KSK_ID"];
			if (!ksk_id) return;
			const res = await $api.ksk.load_ksk_info(ksk_id);
			commit("SET_KSK_INFO", res);
		} catch (error) {
			throw error;
		}
	},

	async load_ksk_info_v2({ rootGetters, commit }) {
		try {
			const ksk_id = rootGetters["auth/GET_KSK_ID"];
			if (!ksk_id) return;
			const res = await $api.ksk.load_ksk_info_v2(ksk_id);
			commit("SET_KSK_INFO", res);
		} catch (error) {
			throw error;
		}
	},

	async load_houses({ rootGetters, commit }, params = {}) {
		try {
			const user = rootGetters["auth/GET_INDIVIDUAL_DATA"];
			if(user.role.name == 'Кск') {
				const kskIds = user.ksk.map(item => item.id);
				const res = await $api.ksk.load_houses({ short: true, ksk_ids: kskIds });
				return commit("SET_HOUSES", res);
			}
			if(user.role.name == 'Диспетчер' || user.role.name == 'Администратор') {
				const res = await $api.ksk.load_houses({ short: true, visible: true });
				return commit("SET_HOUSES", res);
			}
			const houseDataArray = user.ksk_houses.map(item => item.house);
			commit("SET_HOUSES", houseDataArray);
		} catch (error) {
			throw error;
		}
	},
	async load_houses_all({ rootGetters, commit }, params = {}) {
		try {
			const ksk_id = rootGetters["auth/GET_KSK_ID"];
			console.log('params', params)
			const res = await $api.ksk.load_houses({ ksk_ids: [ksk_id] });
			commit("SET_HOUSES", res);

		} catch (error) {
			throw error;
		}
	},

	async load_statistic_req({rootGetters, commit}, data) {
		try {
			const kskId = rootGetters["auth/GET_KSK_ID"];
			var params = {
				company_ids: [kskId],
				is_web: true
			};
			if(data.employees.length) {
				if(Array.isArray(data.employees)) {
					params['employee_workers_ids'] = data.employees
				} else {
					params['employee_workers_ids[]'] = data.employees
				}
			}
			if(data.houses_ids) {
				params.houses_ids = data.houses_ids
			}
			if(data.category_ids) {
				params.category_ids = data.category_ids
			}
			if(data.was_overdue) {
				params.was_overdue = data.was_overdue
			}
			const res = await $api.ksk.load_statistics(params);
			commit("SET_STATISTIC", res);
		} catch (error) {
			throw error;
		}
	},

	async load_employee({ rootGetters, commit }, payload) {
		try {
			const data = rootGetters["auth/GET_INDIVIDUAL_DATA"];
			const ksk_id = rootGetters["auth/GET_KSK_ID"];
			const role = rootGetters["auth/GET_USER_ROLE"];
			const catalog = rootGetters["catalog/GET_CATALOG"];
			let roles = []
			console.log('payload', payload)
			if(role == 'ksk_manager') {
				roles = catalog.roles.filter(role => {
					return role.name_system === 'ksk_employee' || role.name_system === 'ksk_manager';
				});
			}
			if(role == 'ksk') {
				roles = catalog.roles.filter(role => {
					return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
				});
			}
			if(role !== 'administrator' && role !== 'dispatcher') {
				let kskHousesIds = []
				if(!payload?.house_id) {
					kskHousesIds = data.ksk_houses.map(item => item.house.id);
				} else {
					kskHousesIds.push(payload.house_id)
				}

				let kskCategoryIds = []
				if(!payload?.category_id) {
					kskCategoryIds = data.ksk_category.map(item => item.category.id);
				} else {
					console.log('payload.category_id!', payload.category_id)
					kskCategoryIds.push(payload.category_id)
				}
				if(role == 'ksk' && !payload?.requestInfo) {
					var params = {
						company_ids: [ksk_id],
						short: true,
					};
				} else {
					var params = {
						company_ids: [ksk_id],
						ksk_houses_ids: kskHousesIds,
						// ksk_category_ids: kskCategoryIds,
						short: true,
					};
				}
				if(ksk_id) {
					params.selected_ksk_id = ksk_id
				}
				params.role_ids = []
				roles.forEach(role => {
					params.role_ids.push(role.id);
				});
				console.log('params employE!', params)
				const res = await $api.ksk.load_employee(params);
				commit("SET_EMPLOYEE", res);
			} else {
				commit("SET_EMPLOYEE", []);
			}
		} catch (error) {
			throw error;
		}
	},
	async load_employee_dispatcher({ rootGetters, commit }, payload) {
		try {
			const catalog = rootGetters["catalog/GET_CATALOG"];
			let roles = []
			roles = catalog.roles.filter(role => {
				return role.name_system === 'ksk_employee' || role.name_system === 'ksk_dispatcher' || role.name_system === 'ksk_manager';
			});
			var params = {
				company_ids: payload.ksk_id,
				// ksk_category_ids: payload.category_id,
				ksk_houses_ids: payload.house_id,
				selected_ksk_id: payload.selected_ksk_id,
				short: true,
			};
			params.role_ids = []
			roles.forEach(role => {
				params.role_ids.push(role.id);
			});
			console.log('params employE', params)
			const res = await $api.ksk.load_employee(params);
			commit("SET_EMPLOYEE", res);
		} catch (error) {
			throw error;
		}
	},
	async load_tenants({ commit }, params) {
		try {
			const res = await $api.ksk.load_tenants(params);
			commit("SET_TENANTS", res.records);
		} catch (error) {
			throw error;
		}
	},

	async load_organization_types({ commit }) {
		try {
			const res = await $api.ksk.load_organization_types();
			commit("SET_ORGANIZATION_TYPES", res.data);
		} catch (error) {
			throw error;
		}
	},

	change_analytic_type({ commit }, type = null) {
		commit("SET_ANALYTIC", null);
		commit("SET_ANALYTIC_PARAMS", null);
		if (type !== null) commit("SET_ANALYTIC_TYPE", type);
	},

	async load_analytic({ state, commit }) {
		try {
			commit("SET_ANALYTIC_LOADING", true);
			const res = await $api.ksk.load_analytic({
				type: state.analyticType,
				params: state.analyticParams,
			});
			// convert objects to arrays
			const convert = {};
			for (const [key, value] of Object.entries(res)) {
				if (
					Array.isArray(value) ||
					typeof value !== "object" ||
					typeof value[Object.keys(value)[0]] !== "object"
				) {
					convert[key] = value;
					continue;
				}

				const arr = [];
				for (const [objKey, objValue] of Object.entries(value)) {
					if (typeof objValue === "object") arr.push(objValue);
					else arr.push();
				}
				convert[key] = arr;
			}

			console.log(convert);

			commit("SET_ANALYTIC", convert);
		} catch (error) {
			throw error;
		} finally {
			commit("SET_ANALYTIC_LOADING", false);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
