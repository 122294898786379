import analytic from "./ksk/analytic";
import board from "./ksk/board";
import employee from "./ksk/employee";
import entry from "./ksk/entry";
import gates from "./ksk/gates";
import houses from "./ksk/houses";
import notifications from "./ksk/notifications";
import tenants from "./ksk/tenants";
import requests from "./ksk/requests";
import vote from "./ksk/vote";
import qr from "./ksk/qr";
import qrNext from "./ksk/qr-next";
import pricelists from "./ksk/pricelists";
import questions from "./ksk/questions";
import users from "./ksk/users";

export default function(instance) {
	return {
		// * Получение статистики по заявкам
		load_statistics(params) {
			return instance({
				url: `/requests/statistics`,
				method: "get",
				params
			})
				.then(res => res.data)
				.catch(error => error);
		},
		// * Информация о КСК
		// load_ksk_info(ksk_id) {
		// 	return instance({
		// 		url: `/v1/ksk/get/${ksk_id}`,
		// 		method: "get",
		// 	})
		// 		.then(res => res.data)
		// 		.catch(error => error);
		// },

		// * Информация о КСК v2
		load_ksk_info(id) {
			return instance({
				url: `/company/${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Редактирование КСК
		edit_ksk(data, id) {
			return instance({
				url: `/company/${id}`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => error);
		},

		// * Информация о КСК по БИН
		load_ksk_info_bin(bin) {
			return instance({
				url: `/v1/ksk/search`,
				method: "post",
				params: {
					bin,
				},
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},

		// * Получение типов организаций
		// load_organization_types() {
		// 	return instance({
		// 		url: `/v1/ksk/organization/`,
		// 		method: "get",
		// 	})
		// 		.then(res => res.data)
		// 		.catch(error => {
		// 			throw error;
		// 		});
		// },

		// * Создание КСК
		register_ksk(data) {
			return instance({
				url: `/company`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},

		// * Добавление файлов
		load_files(data) {
			return instance({
				url: `/files`,
				method: "post",
				data,
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// Получение файла
		load_file(id) {
			return instance({
				url: `/files/${id}`,
				method: "get",
				responseType: 'blob'
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},
		// * Удаление файлов
		delete_files(id) {
			return instance({
				url: `/files/${id}`,
				method: "delete",
			})
				.then(res => res.data)
				.catch(error => {
					throw error;
				});
		},

		...analytic(instance),
		...board(instance),
		...notifications(instance),
		...houses(instance),
		...entry(instance),
		...employee(instance),
		...users(instance),
		...gates(instance),
		...qr(instance),
		...qrNext(instance),
		...tenants(instance),
		...requests(instance),
		...vote(instance),
		...questions(instance),
		...pricelists(instance),
	};
}
